import { AdminShowCurrentAdminUserResponse } from '@milkamo-inc/camper-interface-admin/api';

import { useApiClient } from 'api/apiClient';
import useFetch from 'api/utils/useFetch';
import toast from 'ui/utils/toast';

export const QUERY_KEY_ADMIN_SHOW_CURRENT_ADMIN_USER =
  'adminServiceAdminShowCurrentAdminUser';

const useShowCurrentAdminUser: UseFetch<
  object,
  AdminShowCurrentAdminUserResponse
> = (payload, options) => {
  const api = useApiClient('adminShowCurrentAdminUser');

  const res = useFetch(
    QUERY_KEY_ADMIN_SHOW_CURRENT_ADMIN_USER,
    () => api(payload),
    {
      ...options,
      onError: (error) => {
        if (error.status !== 403) {
          toast.error('管理者ユーザーの情報取得に失敗しました');
        }
        options?.onError?.(error);
      },
    }
  );

  return res;
};

export default useShowCurrentAdminUser;
