import {
  AdminLoginRequest,
  AdminLoginResponse,
} from '@milkamo-inc/camper-interface-admin/api';

import { usePublicApiClient } from 'api/apiClient';
import useUpdate from 'api/utils/useUpdate';
import toast from 'ui/utils/toast';

export const MUTATION_KEY_LOGIN = 'adminPublicServiceAdminLogin';

const useLogin: UseUpdate<AdminLoginRequest, AdminLoginResponse> = (
  options
) => {
  const api = usePublicApiClient('adminLogin');

  const res = useUpdate(api, {
    mutationKey: MUTATION_KEY_LOGIN,
    ...options,
    // onSuccess: (data, variables, context) => {

    //   options?.onSuccess?.(data, variables, context);
    // },
    onError: (error, variables, context) => {
      toast.error('ログインに失敗しました');

      options?.onError?.(error, variables, context);
    },
  });

  return res;
};

export default useLogin;
