import React, { createContext, useContext, useState, useCallback } from 'react';

import { AdminUser } from '@milkamo-inc/camper-interface-admin/api';
import { useLocation, useNavigate } from 'react-router-dom';

import useShowCurrentAdminUser from 'api/hooks/adminUser/useShowCurrentAdminUser';
import useLogin from 'api/hooks/public/useLogin';
import useLogout from 'api/hooks/public/useLogout';
import token from 'ui/utils/token';

interface AuthContextValue {
  isAuthenticating: boolean;
  user?: AdminUser;
  handleLogin: (email: string, password: string) => Promise<void>;
  handleLogout: () => Promise<void>;
  isTokenExpired: () => Promise<boolean>;
  refetchUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextValue | null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const login = useLogin();
  const logout = useLogout();
  const showCurrentAdminUser = useShowCurrentAdminUser(
    {},
    { enabled: location.pathname.startsWith('/protected') && isAuthenticated }
  );

  const handleLogin = useCallback(
    async (email: string, password: string) => {
      setIsAuthenticating(true);
      const res = await login.mutateAsync(
        { email, password },
        {
          onSuccess: () => {
            setIsAuthenticating(false);
            setIsAuthenticated(true);
          },
          onError: () => setIsAuthenticating(false),
          onSettled: () => {
            setIsAuthenticating(false);
            setIsAuthenticated(true);
          },
        }
      );

      if (!res.token || !res.userName) return;

      token.setToken(res.token);
    },
    [login]
  );

  const handleLogout = useCallback(async () => {
    setIsAuthenticated(false);
    sessionStorage.clear();
    await logout.mutateAsync(
      { token: token.getToken() },
      { onSuccess: () => navigate('/') }
    );
    token.clearToken();
  }, [logout]);

  const isTokenExpired = useCallback(async () => {
    const res = await showCurrentAdminUser.refetch();

    return res.isError;
  }, [token]);

  const value = {
    isAuthenticating,
    user: showCurrentAdminUser.data?.user,
    isTokenExpired,
    handleLogin,
    handleLogout,
    refetchUser: async () => {
      showCurrentAdminUser.refetch();
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
};
