import React, { useEffect } from 'react';

import styled from '@emotion/styled';
import { AdminUserStatus } from '@milkamo-inc/camper-interface-admin';
import { Outlet, useNavigate } from 'react-router-dom';

import token from '../token';

import Loading from 'components/atoms/Loading';
import { useAuthContext } from 'contexts/AuthContext';

const Protected: React.FC = () => {
  const navigate = useNavigate();
  const { isTokenExpired, isAuthenticating, user } = useAuthContext();

  useEffect(() => {
    (async () => {
      const tokenExpired = await isTokenExpired();
      if (isAuthenticating) return;

      if (tokenExpired || user?.status === AdminUserStatus.Inactive) {
        token.clearToken();
        navigate('/');
      }
    })();
  }, [navigate, isAuthenticating, isTokenExpired, user?.status]);

  return !isAuthenticating ? (
    <Outlet />
  ) : (
    <LoadingContainer>
      <Loading />
    </LoadingContainer>
  );
};

export default Protected;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
